import { merge } from "lodash";
import { RootState } from "..";
import { IOverrides } from "../bootstrap/types";

// Selectors
export const getOverridesByChipId = (state: RootState, chipId: number) => {
  const chip = state.chips.byId[chipId];
  return chip.overrides;
};

export const getOverridesByEventId = (state: RootState, eventId: number) => {
  const event = state.events.byId[eventId];
  return event.overrides;
};

export const getOverrides = (
  state: RootState,
  eventId?: number,
  chipId?: number
) => {
  let chipOverrides = {};
  let eventOverrides = {};

  if (!chipId && !eventId) {
    return null;
  }

  if (chipId) {
    chipOverrides = getOverridesByChipId(state, chipId);
  }

  if (eventId) {
    eventOverrides = getOverridesByEventId(state, eventId);
  }

  return merge(eventOverrides, chipOverrides) as IOverrides;
};
