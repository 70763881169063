import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getPrivateClassicCupLeaguesForEntry,
  getPrivateClassicLeaguesForEntry,
  getPrivateH2HLeaguesForEntry,
  getPublicClassicCupLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getPublicH2HLeaguesForEntry,
  getSystemClassicCupLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../core/store/entries/reducers";
import { fetchEntrySummary } from "../core/store/entries/thunks";
import { IEntry, ILeagueEntry } from "../core/store/entries/types";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getTotalPlayers } from "../core/store/game/reducers";
import { fetchMyTeam } from "../core/store/my-team/thunks";
import { ITransferState } from "../core/store/my-team/types";
import { getPlayerData } from "../core/store/player/reducers";
import { getTransferState } from "../core/store/squad/reducers";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeam, ITeamsById } from "../core/store/teams/types";
import { integerToMoney } from "../core/utils/money";
import BoldLink, { BoldHyperlink, BoldLinkWrap } from "./BoldLink";
import { kitFromEntry } from "./EntryUpdate";
import Flag from "./Flag";
import Footnote from "./Footnote";
import Kit from "./kit/Kit";
import Movement from "./leagues/Movement";
import MyLeagueCupSummary from "./leagues/MyLeagueCupSummary";
import { LeagueSummaryTable } from "./leagues/Styles";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
  getLeagueUrl,
} from "./leagues/utils";
import Link from "./Link";
import Panel from "./Panel";
import ReportNameButton from "./ReportNameButton";
import TabHeading from "./TabHeading";
import EntryTabs from "./tabs/EntryTabs";
import TabPanel from "./tabs/TabPanel";
import { VisuallyHidden } from "./Utils";

const EntryName = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const TeamName = styled.div`
  font-size: 2rem;
`;

const DataList = styled.ul`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const DataListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${({ theme }) => theme.space[2]} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const DataListHeading = styled.h5`
  flex: 1 0 65%;
  font-family: ${({ theme }) => theme.fonts.regular};
`;

const DataListValue = styled.div`
  flex: 1 0 35%;
  text-align: right;
`;

const Badge = styled.img`
  display: block;
  margin: 0 auto;
  padding: 3.5rem 0 ${({ theme }) => theme.space[3]};
  width: 100px;
  min-height: 100px;
`;

const MovementHead = styled.th`
  width: 16%;
`;

const RankHead = styled.th`
  width: 30%;
`;

const NameHead = styled.th`
  width: 54%;
`;

const Name = styled.td`
  overflow-wrap: break-word;
  hyphens: auto;
`;

interface ILeagueSummaryProps {
  title: React.ReactNode;
  leagues: ILeagueEntry[];
}

interface IRowProps {
  entry?: IEntry;
  leagueEntry: ILeagueEntry;
}

const LeagueSummary: React.FC<ILeagueSummaryProps> = ({ leagues, title }) => (
  <div>
    <Box mb={2}>
      <TabHeading hasBorder={true} title={title} />
    </Box>
    <LeagueSummaryTable>
      <thead>
        <tr>
          <MovementHead scope="col">
            <VisuallyHidden>Movement</VisuallyHidden>
          </MovementHead>
          <RankHead scope="col">Rank</RankHead>
          <NameHead scope="col">League</NameHead>
        </tr>
      </thead>
      <tbody>
        {leagues.map((l) => (
          <LeagueSummaryRow key={l.id} leagueEntry={l} />
        ))}
      </tbody>
    </LeagueSummaryTable>
  </div>
);

const LeagueSummaryRow: React.FC<IRowProps> = ({ entry, leagueEntry }) => (
  <tr>
    <td>
      <Movement
        lastRank={leagueEntry.entry_last_rank}
        rank={leagueEntry.entry_rank}
      />
    </td>
    <td>
      {leagueEntry.entry_rank ? leagueEntry.entry_rank.toLocaleString() : null}
    </td>
    <Name>
      <Link to={getLeagueUrl(leagueEntry.id, leagueEntry.scoring, entry?.id)}>
        {leagueEntry.name}
      </Link>
    </Name>
  </tr>
);

interface IFanLeagueData {
  league: ILeagueEntry | null;
  team: ITeam | null;
}

interface IPropsFromState {
  entry: IEntry | null;
  mine: Boolean;
  now: IEvent | null;
  privateClassicLeagues: ILeagueEntry[];
  privateClassicCupLeagues: ILeagueEntry[];
  privateH2HLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  publicClassicCupLeagues: ILeagueEntry[];
  publicH2HLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
  systemClassicCupLeagues: ILeagueEntry[];
  teamsById: ITeamsById;
  totalPlayers: number;
  transfersState: ITransferState | null;
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  fetchMyTeam: () => void;
}

interface IOwnProps {
  entryId: number;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;

class Entry extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.mine && !this.props.transfersState) {
      this.props.fetchMyTeam();
    }
    if (!this.props.entry) {
      this.props.fetchEntrySummary(this.props.entryId);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.systemClassicLeagues.lastIndexOf &&
      !prevProps.systemClassicLeagues.lastIndexOf
    ) {
    }
  }

  public render() {
    const {
      entry,
      privateClassicLeagues,
      privateClassicCupLeagues,
      publicClassicLeagues,
      publicClassicCupLeagues,
      privateH2HLeagues,
      publicH2HLeagues,
      systemClassicLeagues,
      systemClassicCupLeagues,
      teamsById,
      totalPlayers,
      transfersState,
      mine = false,
    } = this.props;
    if (!entry) {
      return null;
    }
    const kit = kitFromEntry(entry);

    // Extract any fan league
    const fanLeagueData: IFanLeagueData = {
      league: null,
      team: null,
    };
    const fanLeagueMatch = /^team-(\d+)$/;

    // Please note the "!" non-null assertion operator on the filter
    const fanLeagues = systemClassicLeagues.filter((l) =>
      l.short_name!.match(fanLeagueMatch)
    );
    if (fanLeagues.length) {
      fanLeagueData.league = fanLeagues[0];
      fanLeagueData.team =
        teamsById[fanLeagues[0].short_name!.match(fanLeagueMatch)![1]];
    }

    // Split broadcaster leagues out from system classic leagues
    const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
    const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
      systemClassicLeagues,
      broadcasterLeagues
    );

    const hasCupLeagues =
      privateClassicCupLeagues.length > 0 ||
      publicClassicCupLeagues.length > 0 ||
      systemClassicCupLeagues.length > 0;

    // Split broadcaster cup leagues out from system classic cup leagues
    const broadcasterCupLeagues = getBroadcasterLeagues(
      systemClassicCupLeagues
    );
    const filteredSystemCupLeagues = getFilteredSystemClassicLeagues(
      systemClassicCupLeagues,
      broadcasterCupLeagues
    );

    const getBadgePath = (code: number, density = 1) =>
      `//resources.premierleague.com/premierleague/badges/100/t${code}${
        density === 2 ? "@x2" : ""
      }.png`;

    return (
      <>
        <Box pb={4}>
          <Panel>
            <Panel.Header
              title={
                <>
                  <EntryName>
                    {entry.player_first_name} {entry.player_last_name}
                  </EntryName>
                  <TeamName>{entry.name}</TeamName>
                </>
              }
            >
              <Box m={2}>
                <Flag entry={entry} />
              </Box>
              {!mine && (
                <Box width={1} mx={2} mb={2}>
                  <ReportNameButton entryId={entry.id} />
                </Box>
              )}
            </Panel.Header>
            <Panel.Body isPadded={true}>
              <Box mb={2}>
                <TabHeading title="Points/Rankings" hasBorder={true} />
              </Box>
              <DataList>
                <DataListItem>
                  <DataListHeading>Overall points</DataListHeading>
                  <DataListValue>
                    {entry.summary_overall_points?.toLocaleString()}
                  </DataListValue>
                </DataListItem>
                <DataListItem>
                  <DataListHeading>Overall rank</DataListHeading>
                  <DataListValue>
                    {entry.summary_overall_rank &&
                      entry.summary_overall_rank.toLocaleString()}
                  </DataListValue>
                </DataListItem>
                <DataListItem>
                  <DataListHeading>Total players</DataListHeading>
                  <DataListValue>{totalPlayers.toLocaleString()}</DataListValue>
                </DataListItem>
                <DataListItem>
                  <DataListHeading>Gameweek points</DataListHeading>
                  <DataListValue>{entry.summary_event_points}</DataListValue>
                </DataListItem>
              </DataList>
            </Panel.Body>
            <BoldLinkWrap>
              <BoldLink to={`/entry/${entry.id}/history`}>
                Gameweek History
              </BoldLink>
            </BoldLinkWrap>
          </Panel>
        </Box>
        <Box pb={4}>
          <Panel>
            <Panel.Header title="My Team's Kit" />
            <Panel.Body hasBorder={true}>
              <Kit
                shirtColor={kit["ism-shirt-color"]}
                shirtStyle={kit["ism-shirt-style"]}
                shirtStyleColor={kit["ism-shirt-style-color"]}
                shortColor={kit["ism-short-color"]}
                sleeveColor={kit["ism-sleeve-color"]}
                sockColor={kit["ism-sock-color"]}
                sockStyle={kit["ism-sock-style"]}
                sockStyleColor={kit["ism-sock-style-color"]}
                sponsorLogo={kit["ism-sponsor-logo"]}
              />
            </Panel.Body>
            {mine && (
              <BoldLinkWrap>
                <BoldLink to={`/entry-update`}>Design Your Kit</BoldLink>
              </BoldLinkWrap>
            )}
          </Panel>
        </Box>
        {fanLeagueData.league && fanLeagueData.team && (
          <Box pb={4}>
            <Panel>
              <Panel.Header title="Fan League" />
              <Panel.Body hasBorder={true}>
                <Badge
                  srcSet={`
                    ${getBadgePath(fanLeagueData.team.code)},
                    ${getBadgePath(fanLeagueData.team.code, 2)} 2x
                  `}
                  alt={fanLeagueData.team.name}
                />
              </Panel.Body>
              <BoldLinkWrap>
                <BoldLink
                  to={getLeagueUrl(
                    fanLeagueData.league.id,
                    fanLeagueData.league.scoring
                  )}
                >{`View My Fan League`}</BoldLink>
              </BoldLinkWrap>
            </Panel>
          </Box>
        )}
        <Box pb={4}>
          <Panel>
            <Panel.Header title="Leagues &amp; Cups" />
            <Panel.Body isPadded={true}>
              <EntryTabs>
                <TabPanel label="Leagues" link="leagues">
                  {broadcasterLeagues.length > 0 && (
                    <LeagueSummary
                      title="Broadcaster League"
                      leagues={broadcasterLeagues}
                    />
                  )}
                  {privateClassicLeagues.length > 0 && (
                    <LeagueSummary
                      title="Invitational Classic Leagues"
                      leagues={privateClassicLeagues}
                    />
                  )}
                  {privateH2HLeagues.length > 0 && (
                    <LeagueSummary
                      title="Invitational Head-to-Head Leagues"
                      leagues={privateH2HLeagues}
                    />
                  )}
                  {publicClassicLeagues.length > 0 && (
                    <LeagueSummary
                      title="Public Classic Leagues"
                      leagues={publicClassicLeagues}
                    />
                  )}
                  {publicH2HLeagues.length > 0 && (
                    <LeagueSummary
                      title="Public Head-to-Head Leagues"
                      leagues={publicH2HLeagues}
                    />
                  )}
                  <LeagueSummary
                    title="General Leagues"
                    leagues={filteredSystemClassicLeagues}
                  />
                </TabPanel>
                <TabPanel label="Cups" link="cups">
                  {hasCupLeagues ? (
                    <>
                      {privateClassicCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={privateClassicCupLeagues}
                          title="League cups"
                        />
                      )}
                      {publicClassicCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={publicClassicCupLeagues}
                          title="Public cups"
                        />
                      )}
                      {filteredSystemCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={filteredSystemCupLeagues}
                          title="General cups"
                        />
                      )}
                      {broadcasterCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={broadcasterCupLeagues}
                          title="Broadcaster cups"
                        />
                      )}
                    </>
                  ) : (
                    <Box m={2}>
                      <p>None of your leagues have a cup yet.</p>
                    </Box>
                  )}
                </TabPanel>
              </EntryTabs>
            </Panel.Body>
            <BoldLinkWrap>
              <BoldLink to="/leagues/create-join">
                Create &amp; Join Leagues
              </BoldLink>
            </BoldLinkWrap>
          </Panel>
        </Box>

        {mine && transfersState && (
          <Box mb={4}>
            <Panel>
              <Panel.Header title="Transfers &amp; Finance" />
              <Panel.Body isPadded={true}>
                <Box mb={2}>
                  <TabHeading title="Transfers" hasBorder={true} />
                </Box>
                <DataList>
                  <DataListItem>
                    <DataListHeading>Gameweek transfers</DataListHeading>
                    <DataListValue>{transfersState.made}</DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListHeading>Total transfers</DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_total_transfers +
                        transfersState.made}
                    </DataListValue>
                  </DataListItem>
                </DataList>
              </Panel.Body>
              <BoldLinkWrap>
                <BoldLink to={`/entry/${entry.id}/transfers`}>
                  Transfer History
                </BoldLink>
              </BoldLinkWrap>
              <Panel.Body isPadded={true}>
                <Box mb={2}>
                  <TabHeading title="Finances" hasBorder={true} />
                </Box>
                <DataList>
                  <DataListItem>
                    <DataListHeading>Squad value</DataListHeading>
                    <DataListValue>
                      £{integerToMoney(transfersState.value, 10)}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListHeading>In the bank</DataListHeading>
                    <DataListValue>
                      £{integerToMoney(transfersState.bank, 10)}
                    </DataListValue>
                  </DataListItem>
                </DataList>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {!mine && (
          <Box mb={4}>
            <Panel>
              <Panel.Header title="Transfers &amp; Finance" />
              <Panel.Body isPadded={true}>
                <Box mb={2}>
                  <TabHeading title="Transfers" hasBorder={true} />
                </Box>
                <DataList>
                  <DataListItem>
                    <DataListHeading>Total transfers</DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_total_transfers}
                    </DataListValue>
                  </DataListItem>
                </DataList>
              </Panel.Body>
              <BoldLinkWrap>
                <BoldLink to={`/entry/${entry.id}/transfers`}>
                  Transfer History
                </BoldLink>
              </BoldLinkWrap>
              <Panel.Body isPadded={true}>
                <Box mb={2}>
                  <TabHeading title="Finances" hasBorder={true} />
                </Box>
                <DataList>
                  <DataListItem>
                    <DataListHeading>Squad value</DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_value !== null &&
                      entry.last_deadline_bank !== null
                        ? `£${integerToMoney(
                            entry.last_deadline_value -
                              entry.last_deadline_bank,
                            10
                          )}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListHeading>In the bank</DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_bank !== null
                        ? `£${integerToMoney(entry.last_deadline_bank, 10)}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                </DataList>
                <Box mt={1} px={2}>
                  <Footnote>* Values at the last deadline</Footnote>
                </Box>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {mine && (
          <Panel>
            <Panel.Header title="Admin" />
            <BoldLinkWrap>
              <BoldLink to={`/entry-update`}>Team Details</BoldLink>
            </BoldLinkWrap>
            <BoldLinkWrap>
              <BoldHyperlink
                href={`${process.env.REACT_APP_PLUSERS_BASE}/accounts/manage/?app=plfpl-web&redirect_uri=${window.location.origin}/my-team`}
              >
                User Profile
              </BoldHyperlink>
            </BoldLinkWrap>
          </Panel>
        )}
      </>
    );
  }
}

export { Entry as EntryTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => {
  const player = getPlayerData(state);
  const entryId = ownProps.entryId;
  return {
    entry: getEntry(state, entryId),
    mine: Boolean(player && player.entry && player.entry === entryId),
    now: getCurrentEvent(state),
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entryId),
    privateClassicCupLeagues: getPrivateClassicCupLeaguesForEntry(
      state,
      entryId
    ),
    privateH2HLeagues: getPrivateH2HLeaguesForEntry(state, entryId),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entryId),
    publicClassicCupLeagues: getPublicClassicCupLeaguesForEntry(state, entryId),
    publicH2HLeagues: getPublicH2HLeaguesForEntry(state, entryId),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entryId),
    systemClassicCupLeagues: getSystemClassicCupLeaguesForEntry(state, entryId),
    teamsById: getTeamsById(state),
    transfersState: getTransferState(state),
    totalPlayers: getTotalPlayers(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
