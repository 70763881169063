import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import { IBaseChip, IChip } from "../chips/types";
import { IElementStat } from "../element-stats/types";
import { IElementType } from "../element-types/types";
import { IElement } from "../elements/types";
import { fetchEntrySummary } from "../entries/thunks";
import { IBaseEvent, IEvent } from "../events/types";
import { ISettings } from "../game/types";
import { IPhase } from "../phases/types";
import { IPlayer } from "../player/types";
import { ITeam } from "../teams/types";
import * as actions from "./actions";
import { getBootstrapTime, timeNow } from "./reducers";

export const bootstrap = (): ThunkResult<Promise<void>> => async (dispatch) => {
  dispatch(actions.bootstrap.request());

  const staticRequest = apiGet<{
    chips: IBaseChip[];
    element_stats: IElementStat[];
    element_types: IElementType[];
    elements: IElement[];
    events: IBaseEvent[];
    game_settings: ISettings;
    phases: IPhase[];
    teams: ITeam[];
    total_players: number;
  }>("bootstrap-static/", dispatch, actions.bootstrapStatic);

  const formatChips = (chips: IBaseChip[]): IChip[] => {
    return chips.map((c: IBaseChip) => {
      return {
        ...c,
        overrides: {
          ...c.overrides,
          element_types: {
            byId: c.overrides.element_types.length
              ? Object.fromEntries(
                  c.overrides.element_types.map((elementType: IElementType) => [
                    elementType.id,
                    {
                      ...elementType,
                    },
                  ])
                )
              : {},
          },
        },
      };
    });
  };

  const formatEvents = (events: IBaseEvent[]): IEvent[] => {
    return events.map((event: IBaseEvent) => {
      return {
        ...event,
        overrides: {
          ...event.overrides,
          element_types: {
            byId: event.overrides.element_types.length
              ? Object.fromEntries(
                  event.overrides.element_types.map(
                    (elementType: IElementType) => [
                      elementType.id,
                      {
                        ...elementType,
                      },
                    ]
                  )
                )
              : {},
          },
        },
      };
    });
  };

  const dynamicRequest = apiGet<{ player: IPlayer | null; watched: number[] }>(
    "me/",
    dispatch,
    actions.bootstrapMe
  );

  await Promise.all([staticRequest, dynamicRequest])
    .then(([staticData, meData]) => {
      if (meData.player && meData.player.entry) {
        // If the player has an entry then fetch their summary. This is done
        // asynchronously, users of `getEntry` should be prepared to handle
        // null and delay rendering as necessary
        dispatch(fetchEntrySummary(meData.player.entry));
      }

      dispatch(actions.addChips(formatChips(staticData.chips)));
      dispatch(actions.addElementStats(staticData.element_stats));
      dispatch(actions.addElementTypes(staticData.element_types));
      dispatch(actions.addElements(staticData.elements));
      dispatch(actions.addEvents(formatEvents(staticData.events)));
      dispatch(actions.addGameSettings(staticData.game_settings));
      dispatch(actions.addPhases(staticData.phases));
      dispatch(actions.addTeams(staticData.teams));
      dispatch(actions.addTotalPlayers(staticData.total_players));
      dispatch(actions.addPlayer(meData.player));
      dispatch(actions.addWatched(meData.watched));
      dispatch(actions.bootstrap.success({}));
    })
    .catch((e) => {
      dispatch(actions.bootstrap.failure(e.message));
    });
};

export const checkGame =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const REFRESH_MINUTES = 10;
    const lastBootstrap = getBootstrapTime(getState());
    if (lastBootstrap && timeNow() - lastBootstrap > REFRESH_MINUTES * 60) {
      dispatch(bootstrap());
    }
  };
