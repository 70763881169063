import * as React from "react";
import { default as CountryFlag } from "react-world-flags";
import { IEntry } from "../core/store/entries/types";

interface IProps {
  entry: IEntry;
}

const Flag: React.FC<IProps> = ({ entry }) => {
  const exclusionList = ["RUS", "BLR"];
  const entryLongCode = entry.player_region_iso_code_long;
  const gbPrefix = "GB_";

  if (exclusionList.includes(entryLongCode)) {
    return null;
  }

  const getFlagCode = () => {
    // Work around where GB countries need a prefix and we store the wrong ISO code for Wales and Scotland
    if (entryLongCode === "WAL") {
      return `${gbPrefix}WLS`;
    }
    if (entryLongCode === "SCO") {
      return `${gbPrefix}SCT`;
    }
    if (entryLongCode === "ENG") {
      return `${gbPrefix}ENG`;
    }

    return entryLongCode;
  };

  const flagCode = getFlagCode();
  const customFlagPath = `${process.env.PUBLIC_URL}/img/flags`;

  if (entryLongCode === "NIR") {
    return (
      <img
        src={`${customFlagPath}/${gbPrefix}${flagCode}.svg`}
        alt={entry.player_region_name}
        height="32px"
      />
    );
  }

  return (
    <CountryFlag
      code={flagCode}
      alt={entry.player_region_name}
      height="32px"
      // fallback to old gif if flag not found
      fallback={
        <img
          src={`${customFlagPath}/${entry.player_region_iso_code_short}.gif`}
          alt={entry.player_region_name}
          height="32px"
        />
      }
    />
  );
};

export default Flag;
