import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "..";
import * as actions from "../bootstrap/actions";
import { getOverrides } from "../overrides/reducers";
import { ISettings, IState } from "./types";

export default combineReducers<IState, RootAction>({
  settings: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addGameSettings):
        return action.payload;
      default:
        return state;
    }
  },
  total_players: (state = 0, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addTotalPlayers):
        return action.payload;
      default:
        return state;
    }
  },
});

// State Selectors
export const getSettings = (
  state: RootState,
  eventId?: number,
  chipId?: number
) => {
  const overrides = getOverrides(state, eventId, chipId);
  if (!overrides) {
    return state.game.settings;
  }
  return {
    ...state.game.settings,
    ...overrides.rules,
  } as ISettings;
};

export const getTotalPlayers = (state: RootState) => state.game.total_players;
