import { RouteComponentProps } from "@reach/router";
import moment from "moment";
import { size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState } from "../../core/store";
import { getTeams } from "../../core/store/teams/reducers";
import { ITeam } from "../../core/store/teams/types";
import { ReactComponent as BaseADIcon } from "../../img/icons/audio-description.svg";
import { ReactComponent as BaseCCIcon } from "../../img/icons/closed-caption.svg";
import { ReactComponent as BasePlayIcon } from "../../img/icons/play.svg";
import partnerBadge44 from "../../img/partner-badge-44.png";
import partnerBadge88 from "../../img/partner-badge-88.png";
import { ReactComponent as FacebookLogo } from "../../img/social/facebook.svg";
import { ReactComponent as TwitterLogo } from "../../img/social/twitter.svg";
import {
  fetchNews,
  fetchNewsArticle,
  fetchVideos,
  IPulseNewsArticle,
  IPulseRelatedArticle,
  IPulseResponse,
  IPulseTag,
} from "../../utils/pulse";
import { BoldHyperlink } from "../BoldLink";
import HelmetHead from "../HelmetHead";
import { Main, Secondary, Wrapper } from "../Layout";
import NewsThumb from "../news/NewsThumb";
import { Figure, NewsLink, Thumb } from "../news/styles";
import Panel from "../Panel";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { NewTabCopy } from "../Utils";
import ScoutCard from "./ScoutCard";
import ScoutErrorBoundary from "./ScoutErrorBoundary";
import { NewsLeader } from "./ScoutLeader";
import ScoutNav from "./ScoutNav";
import {
  ScoutBody,
  ScoutItem,
  ScoutList,
  ScoutPattern,
  ScoutTitle,
} from "./styles";

const Widget = styled.section`
  position: relative;
  padding: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

const WidgetHeader = styled.header`
  border-bottom: ${({ theme }) => theme.borders[0]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-bottom: none;
  }
`;

const ScoutFooter = styled.div`
  padding-top: ${({ theme }) => theme.space[2]};
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  text-align: right;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    position: absolute;
    top: 0.3rem;
    right: 0;
    padding-top: 0;
    border-top: none;
  }
`;

const VideoFigure = styled.figure`
  transition: all 0.1s;
`;

const Image = styled.div`
  position: relative;
  overflow: hidden;
`;

const ThumbPlayIcon = styled(BasePlayIcon)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size("auto", "12%")};
    display: inline;
    fill: white;
    left: 50%;
    max-width: 6rem;
    min-width: 4rem;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`;

const RunTime = styled.span`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background: ${({ theme }) => theme.colors.fantasy};
  color: #fff;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  line-height: 2.4rem;
`;

const PlayIconSmall = styled(BasePlayIcon)`
  ${size(10)}
  margin: 0 0.4rem;
  fill: #2f1351;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: 0 ${({ theme }) => theme.space[2]};
  }
`;

const PlayTime = styled.time`
  padding: 0 0.4rem;
  background: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 1.84;
`;

const MainVideoWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0.4rem;
  }
`;

const MainVideoCaption = styled.figcaption`
  ${ScoutPattern}
  padding: 2rem 2rem 3rem;
  background-repeat: no-repeat;
`;

const MainVideoTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.8rem;
  line-height: 1.2;

  ${NewsLink}:hover & {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2.5rem;
  }
`;

const VideoText = styled.p`
  margin: 1rem 0;
  font-size: 1.2rem;
  color: inherit;
  line-height: 1.8;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.5rem;
  }
`;

interface IVideoIcon {
  color?: string;
}

const CCIcon = styled(BaseCCIcon)<IVideoIcon>`
  ${size(12, 18)};
  fill: #6c6c6c;

  ${(props) =>
    props.color === "primary" &&
    css`
      fill: ${(props) => props.theme.colors.primary};
    `}
`;

const ADIcon = styled(BaseADIcon)<IVideoIcon>`
  ${size(12, 18)};
  fill: #6c6c6c;

  ${(props) =>
    props.color === "primary" &&
    css`
      fill: ${(props) => props.theme.colors.primary};
    `}
`;

const ScoutVideo = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
  }
`;

const VideoSection = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 50%;
  }
`;

const VideoList = styled.ul`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.4rem;
  }
`;

const VideoItem = styled.li`
  margin-bottom: ${(props) => props.theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 50%;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
`;

const SocialBody = styled.div`
  padding: ${({ theme }) => theme.space[1]};
`;

const Logo = styled.img`
  margin: 5px;
`;

const SocialLinks = styled.ul`
  padding: ${({ theme }) => theme.space[1]};
  border-radius: 3px;
  margin-bottom: ${({ theme }) => theme.space[2]};
  background-color: white;
`;

const SocialLink = styled.a<ISocialLinkStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.social[props.network].color};
  background-color: ${(props) => props.theme.colors.social[props.network].bg};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const SocialLinkItem = styled.li`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;

const SocialLinkText = styled.span`
  margin-left: 1rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.2rem;
`;

const TwitterWrap = styled.div`
  margin: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0;
  }
`;

interface ISocialIcons {
  color?: string;
  hasCC?: boolean;
  hasAD?: boolean;
}

const SocialIcons: React.FC<ISocialIcons> = ({ color, hasCC, hasAD }) => (
  <Flex>
    <Box mr="2px">{hasCC && <CCIcon color={color} />}</Box>
    <Box>{hasAD && <ADIcon color={color} />}</Box>
  </Flex>
);

interface ISocialLinkStyleProps {
  network: string;
}

type VideoProps = {
  video: any;
};

const Video: React.FunctionComponent<VideoProps> = ({ video }) => {
  return (
    <NewsLink href={`https://www.premierleague.com/video/single/${video.id}`}>
      <Figure>
        <Thumb>
          <ThumbPlayIcon />
          <NewsThumb
            news={video}
            imgSizes={{
              x1: { width: 214, height: 120 },
              x2: { width: 428, height: 240 },
            }}
          />
          <RunTime>
            <PlayIconSmall />
            <PlayTime dateTime={video.duration}>
              {moment(video.duration * 1000).format("mm:ss")}
            </PlayTime>
          </RunTime>
        </Thumb>
        <ScoutBody>
          <ScoutTitle>{video.title}</ScoutTitle>
          <SocialIcons hasCC={video.closedCaptioned} />
        </ScoutBody>
      </Figure>
    </NewsLink>
  );
};

interface IPropsFromState {
  teams: ITeam[];
}

interface IState {
  news: IPulseResponse | null;
  videos: IPulseResponse | null;
  externalNews: Record<string, IPulseNewsArticle> | null;
}

type Props = RouteComponentProps & IPropsFromState;

class Scout extends React.Component<Props, IState> {
  public state: IState = { news: null, videos: null, externalNews: null };

  public async componentDidMount() {
    this.showTwitter();

    // TODO - Should set an error state then can throw in render and use the
    // error boundary
    Promise.all([
      fetchNews(7, "Fantasy%20Football%20Scout"),
      fetchVideos(5),
    ]).then((data) => {
      const [newsData, videosData] = data;

      this.setState({
        news: newsData,
        videos: videosData,
      });

      const relatedTextContentIds = newsData.content
        .slice(1)
        .reduce((acc, cur) => [...acc, ...cur.related.slice(0, 2)], [])
        .filter((r: IPulseRelatedArticle) => r.type === "TEXT")
        .map((r: IPulseRelatedArticle) => r.id);

      const newsRequests: [Promise<IPulseNewsArticle>] =
        relatedTextContentIds.map((id: number) => fetchNewsArticle(id));

      Promise.all(newsRequests).then((articles) => {
        const externalNewsData = articles.filter((a) =>
          a ? a.tags.some((tag: IPulseTag) => tag.label === "External") : false
        );

        if (externalNewsData.length > 0) {
          const externalNewsById: Record<string, IPulseNewsArticle> =
            externalNewsData.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.id]: curr,
              }),
              {}
            );
          this.setState({
            ...this.state,
            externalNews: externalNewsById,
          });
        }
      });
    });
  }

  public showTwitter() {
    const twttr = (window as any).twttr;
    if (twttr && twttr.widgets) {
      twttr.widgets.load();
    }
  }

  public render() {
    const { news, videos, externalNews } = this.state;

    const mainVideo = videos ? videos.content[0] : null;

    return (
      <>
        <Wrapper>
          <Box flex={1} pt={4} mx={2}>
            <Title>The Scout</Title>
            <ScoutNav />
          </Box>
        </Wrapper>
        <Wrapper>
          <HelmetHead
            title="The Scout, Fantasy Football Tips &amp; Advice | Fantasy Premier League"
            description="To get tips and find out about the latest form and stats for Fantasy Premier League players, read what The Scout has to say, on the official website of the Premier League."
          />
          <Main>
            {news && videos && (
              <>
                {/* Promoted Content */}
                <Box mb="4rem">
                  <NewsLeader news={news.content[0]} />
                </Box>
                {/* News */}
                <Widget>
                  <Box mb={3}>
                    <WidgetHeader>
                      <SubHeading>Latest FPL News</SubHeading>
                    </WidgetHeader>
                  </Box>
                  <ScoutList>
                    {news.content.slice(1, 7).map((n) => (
                      <ScoutItem key={n.id}>
                        <ScoutCard
                          externalNews={externalNews}
                          newsItem={n}
                          teams={this.props.teams}
                        />
                      </ScoutItem>
                    ))}
                  </ScoutList>
                  <ScoutFooter>
                    <BoldHyperlink href="https://www.premierleague.com/news?type=Fantasy">
                      More News
                    </BoldHyperlink>
                  </ScoutFooter>
                </Widget>

                {/* Videos */}
                <Widget>
                  <Box mb={3}>
                    <WidgetHeader>
                      <SubHeading>Latest FPL Videos</SubHeading>
                    </WidgetHeader>
                  </Box>
                  <ScoutVideo>
                    <VideoSection>
                      <MainVideoWrap>
                        <NewsLink
                          href={`https://www.premierleague.com/video/single/${mainVideo.id}`}
                        >
                          <VideoFigure>
                            <Image>
                              <ThumbPlayIcon />
                              <NewsThumb
                                news={mainVideo}
                                imgSizes={{
                                  x1: { width: 436, height: 245 },
                                  x2: { width: 872, height: 490 },
                                }}
                              />
                              <RunTime>
                                <PlayIconSmall />
                                <PlayTime dateTime={mainVideo.duration}>
                                  {moment(mainVideo.duration * 1000).format(
                                    "mm:ss"
                                  )}
                                </PlayTime>
                              </RunTime>
                            </Image>
                            <MainVideoCaption>
                              <MainVideoTitle>{mainVideo.title}</MainVideoTitle>
                              <VideoText>{mainVideo.description}</VideoText>
                              <SocialIcons
                                hasCC={mainVideo.closedCaptioned}
                                color="primary"
                              />
                            </MainVideoCaption>
                          </VideoFigure>
                        </NewsLink>
                      </MainVideoWrap>
                    </VideoSection>
                    <VideoSection>
                      <VideoList>
                        {videos.content.slice(1, 5).map((v) => (
                          <VideoItem key={v.id}>
                            <Video video={v} />
                          </VideoItem>
                        ))}
                      </VideoList>
                    </VideoSection>
                  </ScoutVideo>
                  <ScoutFooter>
                    <BoldHyperlink href="https://www.premierleague.com/video/fantasy">
                      More FPL Video
                    </BoldHyperlink>
                  </ScoutFooter>
                </Widget>
              </>
            )}
          </Main>
          <Secondary>
            <Panel>
              <Panel.Header
                title="FPL on Social"
                icon={
                  <Logo
                    srcSet={`${partnerBadge44}, ${partnerBadge88} 2x`}
                    src={partnerBadge44}
                    alt=""
                  />
                }
              ></Panel.Header>
              <SocialBody>
                <SocialLinks>
                  <SocialLinkItem>
                    <SocialLink
                      href="https://www.facebook.com/OfficialFPL"
                      network="facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookLogo />
                      <SocialLinkText>FPL on Facebook</SocialLinkText>
                      <NewTabCopy />
                    </SocialLink>
                  </SocialLinkItem>
                  <SocialLinkItem>
                    <SocialLink
                      href="https://twitter.com/officialfpl"
                      network="x"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterLogo />
                      <SocialLinkText>FPL on X</SocialLinkText>
                      <NewTabCopy />
                    </SocialLink>
                  </SocialLinkItem>
                </SocialLinks>
                <TwitterWrap>
                  <a
                    className="twitter-timeline"
                    data-height="1200"
                    data-link-color="black"
                    href="https://twitter.com/OfficialFPL?ref_src=twsrc%5Etfw"
                  >
                    Tweets by OfficialFPL
                  </a>
                </TwitterWrap>
              </SocialBody>
            </Panel>
          </Secondary>
        </Wrapper>
      </>
    );
  }
}

const ScoutWrapper: React.FC<Props> = ({ teams }) => (
  <ScoutErrorBoundary>
    <Scout teams={teams} />
  </ScoutErrorBoundary>
);

const mapStateToProps = (state: RootState): IPropsFromState => ({
  teams: getTeams(state),
});

export default connect(mapStateToProps)(ScoutWrapper);
