import { createAsyncAction, deprecated } from "typesafe-actions";
import { IError } from "..";
import { IChip } from "../chips/types";
import { IElementStat } from "../element-stats/types";
import { IElementType } from "../element-types/types";
import { IElement } from "../elements/types";
import { IEvent } from "../events/types";
import { ISettings } from "../game/types";
import { IPhase } from "../phases/types";
import { IPlayer } from "../player/types";
import { ITeam } from "../teams/types";

const createAction = deprecated.createAction;

// Action Creators
export const addChips = createAction("ADD_CHIPS", (resolve) => {
  return (chips: IChip[]) => resolve(chips);
});

export const addElementStats = createAction("ADD_ELEMENT_STATS", (resolve) => {
  return (elementStats: IElementStat[]) => resolve(elementStats);
});

export const addElementTypes = createAction("ADD_ELEMENT_TYPES", (resolve) => {
  return (elementTypes: IElementType[]) => resolve(elementTypes);
});

export const addElements = createAction("ADD_ELEMENTS", (resolve) => {
  return (elements: IElement[]) => resolve(elements);
});

export const addEvents = createAction("ADD_EVENTS", (resolve) => {
  return (events: IEvent[]) => resolve(events);
});

export const addGameSettings = createAction("ADD_GAME_SETTINGS", (resolve) => {
  return (settings: ISettings) => resolve(settings);
});

export const addPhases = createAction("ADD_PHASES", (resolve) => {
  return (phases: IPhase[]) => resolve(phases);
});

export const addPlayer = createAction("ADD_PLAYER", (resolve) => {
  return (data: IPlayer | null) => resolve(data);
});

export const addWatched = createAction("ADD_WATCHED", (resolve) => {
  return (watched: number[]) => resolve(watched);
});

export const addTeams = createAction("ADD_TEAMS", (resolve) => {
  return (teams: ITeam[]) => resolve(teams);
});

export const addTotalPlayers = createAction("ADD_TOTAL_PLAYERS", (resolve) => {
  return (total: number) => resolve(total);
});

export const bootstrap = createAsyncAction(
  "BOOTSTRAP_START",
  "BOOTSTRAP_SUCCESS",
  "BOOTSTRAP_FAILURE"
)<void, object, string>();

export const bootstrapMe = createAsyncAction(
  "BOOTSTRAP_ME_START",
  "BOOTSTRAP_ME_SUCCESS",
  "BOOTSTRAP_ME_FAILURE"
)<void, object, IError>();

export const bootstrapStatic = createAsyncAction(
  "BOOTSTRAP_STATIC_START",
  "BOOTSTRAP_STATIC_SUCCESS",
  "BOOTSTRAP_STATIC_FAILURE"
)<void, object, IError>();
