import range from "lodash/range";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "..";
import * as actions from "../bootstrap/actions";
import { getSettings } from "../game/reducers";
import { getOverrides } from "../overrides/reducers";
import { IElementType, IElementTypesBySquadPosition, IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addElementTypes):
        const newState: { [key: string]: IElementType } = {};
        action.payload.forEach((et) => {
          newState[et.id] = et;
        });
        return newState;
      default:
        return state;
    }
  },
});

// State Selectors
export const getElementTypesById = (
  state: RootState,
  eventId?: number,
  chipId?: number
) => {
  const overrides = getOverrides(state, eventId, chipId);
  if (!overrides) {
    return state.elementTypes.byId;
  }
  return {
    ...state.elementTypes.byId,
    ...overrides.element_types.byId,
  };
};

export const getElementTypes = createSelector(
  [(state: RootState, eventId?: number) => getElementTypesById(state, eventId)],
  (elementTypesById) =>
    Object.keys(elementTypesById).map((et) => elementTypesById[et])
);

export const getElementTypesBySquadPosition = createSelector(
  getElementTypes,
  getSettings,
  (types) => {
    const data: IElementTypesBySquadPosition = {};

    // First pass to set types by position
    let count = 1;
    types.forEach((et) => {
      range(et.squad_select).forEach(() => {
        data[count] = { lastType: null, nextType: null, thisType: et };
        count++;
      });
    });

    // Second pass to set last and next types which help with rendering
    count = 1;
    types.forEach((et) => {
      range(et.squad_select).forEach(() => {
        if (data[count - 1]) {
          data[count].lastType = data[count - 1].thisType;
        }
        if (data[count + 1]) {
          data[count].nextType = data[count + 1].thisType;
        }
        count++;
      });
    });

    return data;
  }
);
