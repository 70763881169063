import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "../../../core/store";
import {
  getActiveOrProposedTeamChipName,
  getPotentialChips,
} from "../../../core/store/chips/reducers";
import { chipActivationHandle } from "../../../core/store/chips/thunks";
import { IPotentialChip } from "../../../core/store/chips/types";
import { getEntry } from "../../../core/store/entries/reducers";
import { getEvents } from "../../../core/store/events/reducers";
import { getMyPicksProposed } from "../../../core/store/my-team/reducers";
import { getPlayerData } from "../../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../../core/store/player/types";
import { getTransferState } from "../../../core/store/squad/reducers";
import { formatRawAsLocal } from "../../../core/utils/datetime";
import ChipButton from "../../Chips/ChipButton";
import ChipDialog, {
  ChipDialogBody,
  ChipDialogHeader,
} from "../../Chips/ChipDialog/ChipDialog";
import Copy from "../../Copy";
import DialogManager from "../../DialogManager";
import ChipCard from "../ChipCard";
import {
  ChipButtonWrap,
  ChipCardLink,
  ChipLabel,
  StyledChipList,
} from "./styles";
import {
  IChipDetails,
  IChipListProps,
  IPropsFromDispatch,
  IPropsFromState,
} from "./types";

const ChipList: React.FC<IChipListProps> = ({
  chipInPlayName,
  chips,
  entry,
  events,
  picks,
  activateChip,
  deactivateChip,
  transfersState,
  chipType,
}) => {
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const handlePlayClick = (chipName: string, handleHide: () => void) => {
    let askConfirmation = false;
    if (chipName === "freehit") {
      askConfirmation = transfersState ? transfersState.made > 0 : false;
    } else if (chipName === "wildcard") {
      askConfirmation =
        transfersState && transfersState.limit
          ? transfersState.made > 1
          : false;
    }

    if (!askConfirmation) {
      activateChip(chipName);
      handleHide();
      setShowModalConfirmation(false);
    } else {
      setShowModalConfirmation(true);
    }
  };

  const handleConfirmClick = (chipName: string) => {
    setShowModalConfirmation(false);
    activateChip(chipName);
  };

  const handleCancelClick = () => {
    setShowModalConfirmation(false);
    deactivateChip();
  };

  if (!entry || !picks.length) {
    return null;
  }
  const freeHitChip = chips.filter((c) => c.name === "freehit")[0];
  const freeHitId = "ism-freehit";
  const wildCardChip = chips.filter((c) => c.name === "wildcard")[0];
  const wildCardId = "ism-wildcard";

  const assistantManagerChip: IPotentialChip = {
    status_for_entry: "available",
    played_by_entry: [],
    name: "assistantmanager",
    number: 1,
    start_event: 1,
    stop_event: 38,
    chip_type: "team",
    is_pending: false,
    id: 5,
  };

  const renderTransferChipAction = (
    chip: IPotentialChip,
    id: string,
    handleHide: () => void
  ) => {
    if (!entry) {
      return null;
    }

    switch (chip.status_for_entry) {
      case "available":
        return (
          <ChipButtonWrap>
            {showModalConfirmation ? (
              <>
                <ChipButton
                  variant="play"
                  onClick={() => {
                    handleConfirmClick(chip.name);
                    handleHide();
                  }}
                  aria-describedby={id}
                >
                  Confirm
                </ChipButton>

                <ChipButton
                  variant="cancel"
                  onClick={() => {
                    handleCancelClick();
                    handleHide();
                  }}
                  aria-describedby={id}
                >
                  Cancel
                </ChipButton>
              </>
            ) : (
              <ChipButton
                variant="play"
                onClick={() => {
                  handlePlayClick(chip.name, handleHide);
                }}
                aria-describedby={id}
              >
                Play Chip
              </ChipButton>
            )}
          </ChipButtonWrap>
        );
      case "unavailable":
        return <ChipLabel>Unavailable</ChipLabel>;
      case "active":
        if (chip.is_pending) {
          return (
            <ChipButton
              variant="cancel"
              onClick={() => {
                handleHide();
                deactivateChip();
              }}
              aria-describedby={id}
            >
              Cancel
            </ChipButton>
          );
        }
        return <ChipLabel>Active</ChipLabel>;
      default:
        return "";
    }
  };

  const renderChipAction = (
    c: IPotentialChip,
    id: string,
    handleHide: () => void
  ) => {
    if (!entry) {
      return null;
    }

    switch (c.status_for_entry) {
      case "available":
        return (
          <ChipButton
            variant="play"
            onClick={() => {
              handleHide();
              activateChip(c.name);
            }}
            aria-describedby={id}
          >
            Play Chip
          </ChipButton>
        );

      case "unavailable":
        return <ChipLabel>Unavailable</ChipLabel>;
      case "proposed":
        return (
          <ChipButton
            variant="cancel"
            onClick={() => {
              handleHide();
              deactivateChip();
            }}
            aria-describedby={id}
          >
            Cancel
          </ChipButton>
        );
      case "active":
        return (
          <ChipButton
            variant="cancel"
            onClick={() => {
              handleHide();
              deactivateChip();
            }}
            aria-describedby={id}
          >
            Cancel
          </ChipButton>
        );
      case "cancelled":
        return (
          <ChipButton
            variant="play"
            onClick={() => {
              handleHide();
              activateChip(c.name);
            }}
            disabled={Boolean(chipInPlayName)}
            aria-describedby={id}
          >
            Activate
          </ChipButton>
        );
      default:
        return "";
    }
  };

  const chipDetails: { [key: string]: IChipDetails } = {
    bboost: {
      name: "Bench Boost",
      description: (
        <Copy>
          <p>
            The points scored by your benched players in a Gameweek will be
            added to your total.
          </p>
          <p>It can be cancelled at anytime before the Gameweek deadline.</p>
        </Copy>
      ),
    },
    "3xc": {
      name: "Triple Captain",
      description: (
        <Copy>
          <p>
            The points scored by your captain will be tripled instead of doubled
            in a Gameweek.
          </p>
          <p>It can be cancelled at anytime before the Gameweek deadline.</p>
        </Copy>
      ),
    },
    freehit: {
      name: "Free Hit",
      description: (
        <Copy>
          {showModalConfirmation ? (
            <p>
              <strong>
                You have already made a transfer this Gameweek so you will not
                be able to cancel this chip once played.
              </strong>
            </p>
          ) : (
            <>
              <p>
                Make unlimited free transfers for a single Gameweek. In the next
                Gameweek, your squad will return to how it was at the start of
                the last Gameweek.
              </p>
              <p>
                This chip cannot be cancelled after you play it, unless you have
                not yet made a transfer this Gameweek.
              </p>
            </>
          )}
        </Copy>
      ),
    },
    assistantmanager: {
      name: "Assistant Manager",
      description: (
        <Copy>
          <p>
            Add a manager to your team to score points for three consecutive
            gameweeks.
          </p>
          <p>
            The Assistant Manager chip can be used once a season. Your initial
            manager selection will not cost a transfer. Any further manager
            transfers will either cost a free transfer or -4 points if you do
            not have a free transfer available.
          </p>
          <p>
            This chip cannot be cancelled once you activate it and confirm a
            manager transfer.
          </p>
          <strong>Available from Gameweek 24.</strong>
        </Copy>
      ),
    },
    wildcard: {
      name: "Wildcard",
      description: (
        <Copy>
          {showModalConfirmation ? (
            <p>
              <strong>
                You have already made a transfer this Gameweek so you will not
                be able to cancel this chip once played.
              </strong>
            </p>
          ) : (
            <>
              <p>
                Make unlimited permanent transfers in a Gameweek without
                incurring the usual 4 point cost for each.
              </p>
              <p>{`The second Wildcard will be available after ${formatRawAsLocal(
                events[18].deadline_time
              )}.`}</p>
              <p>
                This chip cannot be cancelled after you play it, unless you have
                not yet made a cost transfer this Gameweek.
              </p>
            </>
          )}
        </Copy>
      ),
    },
  };

  return (
    <StyledChipList $chipType={chipType}>
      {chips
        .filter((c) => ["3xc", "bboost"].indexOf(c.name) > -1)
        .map((c) => {
          const chipId = `ism-${c.name}`;
          return (
            (chipType === "team" || chipType === "all") && (
              <>
                {c.status_for_entry === "played" ? (
                  <ChipCardLink
                    to={`/entry/${entry.id}/event/${c.played_by_entry[0]}`}
                  >
                    <ChipCard
                      chip={c}
                      title={chipDetails[c.name].name}
                      disabled={false}
                    />
                  </ChipCardLink>
                ) : (
                  <DialogManager
                    key={chipId}
                    render={(showDialog, handleShow, handleHide) => (
                      <>
                        <ChipCard
                          chip={c}
                          onClick={handleShow}
                          title={chipDetails[c.name].name}
                          disabled={false}
                        />
                        {showDialog && (
                          <ChipDialog closeDialog={handleHide}>
                            <ChipDialogHeader
                              closeDialog={handleHide}
                              chip={c}
                            />
                            <ChipDialogBody
                              title={chipDetails[c.name].name}
                              chipAction={(c, chipId) =>
                                renderChipAction(c, chipId, handleHide)
                              }
                              chip={c}
                              chipId={chipId}
                            >
                              {chipDetails[c.name].description}
                            </ChipDialogBody>
                          </ChipDialog>
                        )}
                      </>
                    )}
                  />
                )}
              </>
            )
          );
        })}

      {freeHitChip && (chipType === "transfer" || chipType === "all") && (
        <>
          {freeHitChip.status_for_entry === "played" ? (
            <ChipCardLink
              to={`/entry/${entry.id}/event/${freeHitChip.played_by_entry[0]}`}
            >
              <ChipCard
                chip={freeHitChip}
                title={chipDetails[freeHitChip.name].name}
                disabled={Boolean(chipInPlayName)}
              />
            </ChipCardLink>
          ) : (
            <DialogManager
              render={(showDialog, handleShow, handleHide) => (
                <>
                  <ChipCard
                    chip={freeHitChip}
                    onClick={handleShow}
                    title={chipDetails[freeHitChip.name].name}
                    disabled={Boolean(chipInPlayName)}
                  />
                  {showDialog && (
                    <ChipDialog closeDialog={handleHide}>
                      <ChipDialogHeader
                        closeDialog={handleHide}
                        chip={freeHitChip}
                      />
                      <ChipDialogBody
                        title={chipDetails[freeHitChip.name].name}
                        chipAction={(c, chipId) =>
                          renderTransferChipAction(
                            freeHitChip,
                            freeHitId,
                            handleHide
                          )
                        }
                        chip={freeHitChip}
                        chipId={freeHitId}
                      >
                        {chipDetails[freeHitChip.name].description}
                      </ChipDialogBody>
                    </ChipDialog>
                  )}
                </>
              )}
            />
          )}
        </>
      )}
      {wildCardChip && (chipType === "transfer" || chipType === "all") && (
        <>
          {wildCardChip.status_for_entry === "played" ? (
            <ChipCardLink
              to={`/entry/${entry.id}/event/${wildCardChip.played_by_entry[0]}`}
            >
              <ChipCard
                chip={wildCardChip}
                title="Wildcard"
                disabled={Boolean(chipInPlayName)}
              />
            </ChipCardLink>
          ) : (
            <DialogManager
              render={(showDialog, handleShow, handleHide) => (
                <>
                  <ChipCard
                    chip={wildCardChip}
                    onClick={handleShow}
                    title="Wildcard"
                    disabled={Boolean(chipInPlayName)}
                  />
                  {showDialog && (
                    <ChipDialog closeDialog={handleHide}>
                      <ChipDialogHeader
                        closeDialog={handleHide}
                        chip={wildCardChip}
                      />
                      <ChipDialogBody
                        title={"Wildcard"}
                        chipAction={(c, chipId) =>
                          renderTransferChipAction(
                            wildCardChip,
                            wildCardId,
                            handleHide
                          )
                        }
                        chip={wildCardChip}
                        chipId={wildCardId}
                      >
                        {chipDetails[wildCardChip.name].description}
                      </ChipDialogBody>
                    </ChipDialog>
                  )}
                </>
              )}
            />
          )}
        </>
      )}
      {/* To be updated when AM chip is released */}
      {assistantManagerChip && (chipType === "team" || chipType === "all") && (
        <DialogManager
          render={(showDialog, handleShow, handleHide) => (
            <>
              <ChipCard
                chip={assistantManagerChip}
                onClick={handleShow}
                title={chipDetails[assistantManagerChip.name].name}
              />
              {showDialog && (
                <ChipDialog closeDialog={handleHide}>
                  <ChipDialogHeader
                    closeDialog={handleHide}
                    chip={assistantManagerChip}
                  />
                  <ChipDialogBody
                    title={chipDetails[assistantManagerChip.name].name}
                  >
                    {chipDetails[assistantManagerChip.name].description}
                  </ChipDialogBody>
                </ChipDialog>
              )}
            </>
          )}
        />
      )}
    </StyledChipList>
  );
};

export { ChipList as ChipListTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  return {
    chipInPlayName: getActiveOrProposedTeamChipName(state),
    chips: getPotentialChips(state),
    entry: getEntry(state, player.entry),
    picks: getMyPicksProposed(state),
    transfersState: getTransferState(state),
    events: getEvents(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  activateChip: (name) => dispatch(chipActivationHandle(name)),
  deactivateChip: () => dispatch(chipActivationHandle(null)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChipList);
